import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VCharts from 'v-charts-v2'
Vue.use(VueRouter)
import router from './router/index'
import http from './requests/request'
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.prototype.$http=http
Vue.use(VCharts)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
