<template>
  <div id="app">
   <index />
  </div>
</template>

<script>

import index from './components/index.vue'
export default {
  name: 'App',
  components:{
    index:index
  },
  
 
}
</script>

<style>
*{
  margin: 0;
}
#app {
 height: 100%;
}
 a{
    /* color:grey; */
    text-decoraction: none ;
 }
 .router-link-active{
   text-decoraction: none;
 }
 .router-link-exact-active {
    /* color: orangered; */
}

</style>
