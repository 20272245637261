<template>
  <div>
    <el-header height="" id="yj_bot">
      <div
        ref="vanta"
        :style="{ height: navheight + 'px' }"
        :class="{ fixed: isfixed }"
      >
        <div class="nav">
          <el-menu
            class="el-menu-demo"
            :default-active="$route.path"
            :router="true"
            mode="horizontal"
            active-text-color="#fff"
            text-color="#fff"
            @select="handleSelect"
          >
            <el-menu-item index="/home">
              <i
                class="el-icon-s-home"
                style="color: #fff"
                text_aline="center"
              />
              Home<i class="el-icon--right"></i>
            </el-menu-item>

            <el-menu-item index="/metabolism">
              <i class="el-icon-s-management" style="color: #fff" />
              Metabolism
            </el-menu-item>
            <el-menu-item index="#">
              <i class="el-icon-folder-opened" style="color: #fff" />
              Download
            </el-menu-item>
            <el-menu-item index="/contactus">
              <i class="el-icon-s-custom" style="color: #fff" />
              Contact Us
            </el-menu-item>
          </el-menu>
        </div>

        <div class="topheader" v-show="navheight > 50">
          <div class="title">
            <el-avatar class="logo" :size="130" :src="logourl"></el-avatar>
            Metabolism Pathway of Tea Polyphenols
          </div>
        </div>
      </div>
    </el-header>
    <el-main class="main">
      <router-view></router-view>
    </el-main>
    <el-footer class="footer" height="">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="6" style="line-height: 29px"> </el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple-light"></div
        ></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="6"> </el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple-light"></div
        ></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
      <div style="color: #909399">
        2022 © State Key Laboratory of Tea Plant Biology and Utilization, Anhui
        Agricultural University, Hefei, 230036, Anhui, China. Site Stats
      </div>
    </el-footer>
  </div>
</template>

<script>
import logourl from "@/assets/logo.png";
import * as THREE from "three";
import BIRDS from "vanta/src/vanta.net";
export default {
  name: "HelloWorld",
  data() {
    return {
      activeIndex: "home",
      theme1: "primary",
      logourl: logourl,
      navheight: 300,
      navdisplay: "block",
      isfixed: false,
      vantaEffect: undefined,
    };
  },
  mounted() {
    // console.log(this.$route.path);
    this.vanta();
    window.addEventListener("scroll", this.shownav);
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
  methods: {
    vanta() {
      this.vantaEffect = BIRDS({
        el: this.$refs.vanta,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        scale: 1.0,
        minHeight: this.navheight,
      });
    },
    handleSelect(e) {
      console.log(e);
      let _this = this;
      // if(e=='/home'){
      this.$nextTick(() => {
        _this.navheight = 350;
        _this.isfixed = false;
        if (_this.vantaEffect) {
          _this.vantaEffect.destroy();
        }

        _this.vanta();
      });
    },
    shownav(e) {
      // let _this=this
      // let height = e.srcElement.scrollingElement.scrollTop;
      console.log(e.scrollTop);
      // console.log(this.$route.path)
      if (this.$route.path != "/home") {
        //   if (height > 150) {
        //     console.log('kaile')
        //     this.$nextTick(() => {
        //       this.navheight = 50;
        //       this.isfixed=true
        //        if (_this.vantaEffect) {
        //   _this.vantaEffect.destroy();
        // }
        //       _this.vanta()
        //     });
        //   } else if(height<20) {
        //     this.$nextTick(() => {
        //     _this.navheight = 350;
        //     _this.isfixed=false
        //     if (_this.vantaEffect) {
        //   _this.vantaEffect.destroy();
        // }
        //     _this.vanta()
        //     })
        //   }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fixed {
  position: fixed !important;
  width: 100%;
  z-index: 99;
  top: 0;
  animation: searchTop 0.5s ease-in-out;
}
#yj_bot {
  z-index: -1;
}
.topheader {
  width: 100%;
  // height: 350px;
  //  background-image: url("../assets/image/topbg.png");
  color: #fff;
  font-size: 35px;
  .title {
    padding-left: 20%;
    padding-top: 20px;
    height: 180px;
  }
  .vanta-canvas {
    z-index: -1;
  }
}
.nav {
  // background-color: #2d8cf0;
  margin: 0;
  padding: 0;
  ul,
  li {
    background: none !important;
  }
  .el-menu.el-menu--horizontal {
    border: none;
  }
}
.nav .el-menu {
  margin: 0 auto;
  width: 60%;
}
.nav .el-menu-item {
  font-size: 18px !important;
}
.logo {
  vertical-align: middle;
  background: transparent;
}
.nav .el-dropdown-link {
  font-size: 18px;
  color: #fff;
}
.main {
  height: 100%;
  min-height: 500px;
  width: 100%;
}
.footer {
  background-image: url("../assets/image/footer.png");
  background-size: cover;
  // height: 200px!important;
  min-height: 80px !important;
  line-height: 80px;
  color: seashell;
  padding-top: 30px;
  text-align: center;
}
.el-footer {
  margin: 0;
  padding: 0;
}
.el-header {
  padding: 0 0;
}
.el-card /deep/ .el-card__header {
  padding: 0;
}
i {
  border-bottom-color: rgb(255, 255, 255);
  color: #fff;
}
</style>
