import VueRouter from "vue-router";
var router = new VueRouter({
  base: "/",
  mode: "history", //去掉路由#号
  routes: [
    {
      path: "/",
      // component:()=>import('../components/index.vue')
      redirect: "/home",
    },
    {
      path: "/home",
      component: () => import("../components/home/home.vue"),
    },
    {
      path: "/home/search",
      component: () => import("../components/home/search/search.vue"),
    },
    {
      path: "/home/searchc",
      component: () => import("../components/home/search/searchc.vue"),
    },
    {
      path: "/home/chemical1",
      component: () => import("../components/home/chemical/chemical1.vue"),
    },
    {
      path: "/contactus",
      component: () => import("../components/contactus/ContactUs.vue"),
    },
    {
      path: "/download",
      component: () => import("../components/download/download.vue"),
    },
    {
      path: "/metabolism",
      component: () => import("../components/metabolism/MetabolismList.vue"),
    },
    {
      path: "/metabolism/detail",
      component: () => import("../components/metabolism/MetDetail.vue"),
    },
    {
      path: "/metabolism/metablismview",
      component: () => import("../components/metabolism/models/MetView.vue"),
    },
    {
      path: "/metabolism/metablismviewfen",
      component: () => import("../components/metabolism/models/MetViewfen.vue"),
    },
    {
      path: "/metabolism/metmapdetail",
      component: () => import("../components/metabolism/models/MetDetail.vue"),
    },
    // ShikimateGallic
    {
      path: "/metabolism/ShikimateGallic",
      component: () => import("../components/metabolism/metdetail/ShikimateGallic.vue"),
    },
    // AnthocyaninsBiosynthetic
    {
      path: "/metabolism/AnthocyaninsBiosynthetic",
      component: () => import("../components/metabolism/metdetail/AnthocyaninsBiosynthetic.vue"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  // let isLogin = !!localStorage.eleToken
  // const now_path = to.path.split('/')

  // if (to.path === '/selectsym'||to.path === '/index'||to.path === '/login' || to.path ==='/register' || now_path[1] === 'sym') return next()

  next();
});
export default router;
