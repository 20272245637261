const devUrl = 'http://127.0.0.1:8085';  //和代理相对应的请求地址
const proUrl = 'https://api.tea.jiaotu.cc';  //和代理相对应的请求地址
/* *api接口的统一管理* */
// import axios from "axios";
export default {
    baseUrl: proUrl,
    
}




